<template>
    <v-container>
        <h5 class="text-h5 mb-5 primary--text">Interest questions</h5>
        <v-expand-transition>
          <v-alert
          v-show="alert"
          border="left"
          text
          :type="alertType"
          >
            {{alertText}}
          </v-alert>
        </v-expand-transition>

        <v-card>
            <v-card-title class="primary--text text-capitalize font-weight-bold">write a question</v-card-title>
            <v-card-text>
                <v-form v-model="valid" ref="questionsForm">
                    <v-text-field :rules="requiredRule" v-model="question.interest_question" placeholder="Write a question" rounded outlined></v-text-field>
                    <div v-if="$route.params.action === 'edit'">
                      <div v-for="i in question.answers" :key="i">
                          <p class="body-1 font-weight-medium">Answer {{i}}</p>
                          <v-text-field v-model="question.answers[i - 1]" placeholder="Add an answer" rounded outlined></v-text-field>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="n in 4" :key="n">
                        <p class="body-1 font-weight-medium">Answer {{n}}</p>
                        <v-text-field v-model="question.answers[n - 1]" placeholder="Add an answer" rounded outlined></v-text-field>
                      </div>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

        <div class="text-center">
            <v-btn x-large rounded elevation="0" class="px-15 my-10 mx-5 white primary--text" link to="/admin/interests">Cancel</v-btn>
            <v-btn x-large rounded elevation="0" class="px-15 my-10 mx-5 primary white--text" @click="submitQuestion" :loading="loadingBtn">Submit</v-btn>
        </div>
    </v-container>
</template>

<script>
import admin from '@/web_services/admin'

export default {
  data () {
    return {
      alert: '',
      alertType: 'success',
      alertText: '',
      requiredRule: [v => !!v || 'Please fill this field'],
      valid: true,
      question: {
        interest_question: '',
        answers: []
      },
      loadingBtn: false
    }
  },
  methods: {
    submitQuestion () {
      this.$refs.questionsForm.validate()
      if (this.valid) {
        this.loadingBtn = true
        admin.addInterestQuestion(this.question).then(res => {
          this.showAlert('success', 'Created Successfully')
          this.$refs.questionsForm.reset()
        }).catch((error) => {
          let text = ''
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              value.forEach(item => {
                text += item + ' ! '
              })
            })
          } else text = error.response.data.message
          this.showAlert('error', text)
        }).finally(() => {
          this.loadingBtn = false
        })
      }
    },
    showAlert (type, message) {
      this.alertText = message
      this.alertType = type
      this.alert = true
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    }
  },
  created () {
    if (this.$route.params.action === 'add') this.question.answers = new Array(4)
  }
}
</script>
