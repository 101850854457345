import { render, staticRenderFns } from "./agency.vue?vue&type=template&id=4b0f58b7&"
import script from "./agency.vue?vue&type=script&lang=js&"
export * from "./agency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports