<template>
    <!-- <v-card class="rounded-lg agency-bookings"> -->
        <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-3 agency-bookings text-h6"
        :loading="loading"
        >
        <!-- agency trip bookings -->
            <template v-if="listType === 'trips'" v-slot:item.booking_reference="{ item }">
                <div>
                    <v-btn @click="$router.push({ name: 'agencyBookingDetails', params: { id: item.id } })" color="blue" text>{{item.booking_reference}}</v-btn>
                </div>
            </template>

            <template v-if="listType === 'trips'" v-slot:item.trip="{ item }">
                <div>
                    <v-btn
                    @click="$store.dispatch('setAgencyTrip', item.trip); $router.push({ name: 'agencyTripDetails', params: { id: item.trip.id } })"
                    color="blue" text>
                    {{item.trip.name}}
                    </v-btn>
                </div>
            </template>

            <template v-if="listType === 'trips'" v-slot:item.traveller="{ item }">
                <div class="d-flex align-center">
                    <v-img max-width="50" class="rounded-circle ma-2" :src="item.traveller.image"></v-img>
                    {{ item.traveller.name }}
                </div>
            </template>

            <template v-if="listType === 'trips'" v-slot:item.action="{ item }">
                <v-btn @click="$emit('accept', item.id)" color="success" text v-if="item.status === 'notConfirmed'"><v-icon>mdi-check</v-icon> Accept</v-btn>
                <div class="success--text" v-if="item.status === 'confirmed'"><v-icon>mdi-check</v-icon> Confirmed</div>
            </template>

        <!-- agency requests -->
            <template v-if="listType === 'agency'" v-slot:item.name="{ item }">
                <div>
                    <v-btn @click="$router.push({ name: 'agencyDetails', params: { id: item.id } })" color="blue" text>{{item.name}}</v-btn>
                </div>
            </template>

            <template v-if="listType === 'agency'" v-slot:item.user="{ item }">
                <div>
                    {{item.user.user_name}}
                </div>
            </template>

            <template v-if="listType === 'agency'" v-slot:item.created_at="{ item }">
                <div>
                    {{new Date(item.created_at.substr(0, 10).replaceAll('-', '/')).toDateString()}}
                </div>
            </template>

            <template v-if="listType === 'agency'" v-slot:item.actions="{ item }">
                <v-btn @click="updateAgencyStatus(item.id, 'Active')" color="success" text><v-icon left>mdi-check</v-icon> Accept</v-btn>
                <v-btn @click="updateAgencyStatus(item.id, 'Inactive')" color="error" text><v-icon left>mdi-close</v-icon> reject</v-btn>
            </template>
        </v-data-table>
    <!-- </v-card> -->
</template>

<script>
import admin from '@/web_services/admin'

export default {
  props: ['headers', 'items', 'listType'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    updateAgencyStatus (id, status) {
      this.loading = true
      const payload = {
        status: status.toLowerCase()
      }
      admin.updateAgencyStatus(id, payload).then(res => {
        this.$emit('clearPendingAgency', id)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
.agency-bookings .v-data-table-header th.sortable {
    padding: 25px;
    background-color: #FAFAFA;
}
.agency-bookings .v-data-table .v-data-table__wrapper table tbody tr:hover
{
    background: red;
}
</style>
