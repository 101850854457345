<template>
    <v-container>
        <h5 class="text-h5 mt-0 mb-5 primary--text text-capitalize">Agency Details</h5>
        <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        >
          <v-tab>
              Profile Info
          </v-tab>
          <v-tab>
              Trips
          </v-tab>
        </v-tabs>

        <div v-if="!tab">
            <div class="d-flex justify-center" v-if="loadingAgency">
                <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
                class="my-10"
                ></v-progress-circular>
            </div>
            <v-card class="pa-5 my-5 rounded-xl" v-if="agency">
                <v-row>
                    <v-col cols="12" md="3" class="text-center">
                        <v-img class="rounded-circle mb-3 mx-auto" height="150" width="150" v-if="agency.user.profile_photo" :src="agency.user.profile_photo"></v-img>
                        <v-img v-else src="../../assets/images/agency.png" height="150" width="150" class="mx-auto mb-3 rounded-circle"></v-img>
                        <h3>{{agency.name}}</h3>
                    </v-col>
                    <v-col cols="12" md="9" class="pl-5">
                        <div class="d-flex justify-space-between">
                            <h3 class="primary--text">About the company</h3>
                        </div>
                        <p class="body-1 pr-10 mt-5" v-if="agency.user.about_me && agency.user.about_me !== 'null'">{{agency.user.about_me}}</p>
                        <p class="body-1" v-else>No description yet!</p>
                    </v-col>
                </v-row>
            </v-card>

            <v-card class="pa-5 my-10 rounded-xl" :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'" v-if="agency">
                <div class="d-flex justify-space-between mb-5">
                    <h3 class="primary--text">Company Info</h3>
                </div>
                <v-row class="text-capitalize">
                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-account</v-icon>agency name:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.name}}</p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-account</v-icon>agency user name:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.user.user_name}}</p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-web</v-icon>website:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.website}}</p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-phone</v-icon>contact number:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.phone}}</p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-mail</v-icon>Email:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.user.email}}</p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <h3><v-icon class="mr-2">mdi-map</v-icon>location:</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <p class="text-h6 mx-5 grey--text">{{agency.address}}</p>
                    </v-col>
                </v-row>
            </v-card>
            <div class="d-flex justify-center" v-if="agency.user.status === 'Pending'">
                <v-btn rounded class="px-15 mx-1" color="error" @click="updateAgencyStatus(agency.id, 'Inactive')" elevation="0">Reject</v-btn>
                <v-btn rounded class="px-15 mx-1" color="success" @click="updateAgencyStatus(agency.id, 'Active')" elevation="0">Accept</v-btn>
            </div>
        </div>

        <div v-else>
            <v-row class="my-10" no-gutters>
                <template v-if="agency.trips.length">
                    <v-col cols="12" sm="6" md="4" xl="3" v-for="item in agency.trips" :key="item.id" class="pa-5">
                        <trip-card :agencyId="agency.id" :trip="item"></trip-card>
                    </v-col>
                </template>
                <p class="mx-auto text-h5 my-10" v-else>No trips Yet!</p>
            </v-row>
        </div>

        <p v-if="!agency && !loadingAgency" class="text-h6 text-center">No details available!</p>
    </v-container>
</template>

<script>
import admin from '@/web_services/admin'
import tripCard from '@/components/vendors/tripCard'

export default {
  components: {
    tripCard
  },
  data () {
    return {
      tab: 0,
      agency: null,
      loadingAgency: true
    }
  },
  methods: {
    updateAgencyStatus (id, status) {
      this.loading = true
      const payload = {
        status: status.toLowerCase()
      }
      admin.updateAgencyStatus(id, payload).then(res => {
        this.agency.user.status = status
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    admin.agencyDetails(this.$route.params.id).then(response => {
      this.agency = response.data.data
    }).catch(() => {}).finally(() => { this.loadingAgency = false })
  }
}
</script>
