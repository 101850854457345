<template>
    <v-container>
        <h5 class="text-h5 mt-0 mb-5 primary--text text-capitalize">Agencies</h5>
        <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        >
          <v-tab>
              Agencies List
          </v-tab>
          <v-tab>
              Agencies Registeration Requests
          </v-tab>
        </v-tabs>

        <div v-if="!tab">
          <div class="d-flex justify-center" v-if="loadingAgencies">
            <v-progress-circular
            :size="150"
            color="primary"
            indeterminate
            class="my-10"
            ></v-progress-circular>
          </div>
          <v-row class="my-10" no-gutters v-else>
            <template v-if="agencies.length">
                <v-col cols="12" sm="6" md="4" xl="3" v-for="item in agencies" :key="item.id" class="pa-5">
                    <agency-card :agency="item"></agency-card>
                </v-col>
            </template>
            <p class="mx-auto text-h5 my-10" v-else>No agencies Yet!</p>
          </v-row>
        </div>
        <div v-else class="mt-10">
          <booking-table @clearPendingAgency="clearPendingAgency" :headers="headers" :items="pendingAgencies" listType="agency" />
        </div>
    </v-container>
</template>

<script>
import admin from '@/web_services/admin'
import agencyCard from '@/components/admin/agencyCard'
import bookingTable from '@/components/vendors/bookingTable.vue'

export default {
  components: {
    agencyCard,
    bookingTable
  },
  data () {
    return {
      tab: 0,
      activeAgencies: [],
      inactiveAgencies: [],
      loadingAgencies: true,
      pendingAgencies: [],
      headers: [
        { text: 'Agency Name', value: 'name' },
        { text: 'User Name', value: 'user' },
        { text: 'Date', value: 'created_at' },
        { text: 'Status', value: 'user.status' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },
  computed: {
    agencies () {
      return [...this.activeAgencies, ...this.inactiveAgencies]
    }
  },
  methods: {
    clearPendingAgency (id) {
      this.pendingAgencies.splice(this.pendingAgencies.findIndex(a => a.id === id), 1)
      this.getActivities()
    },
    getActivities () {
      admin.getActiveAgencies().then(res => {
        this.activeAgencies = res.data.data
      }).catch(() => {
        this.agencies = []
      })
      admin.getInActiveAgencies().then(res => {
        this.inactiveAgencies = res.data.data
      }).catch(() => {}).finally(() => {
        this.loadingAgencies = false
      })
    }
  },
  created () {
    this.getActivities()
    admin.getPendingAgencies().then(res => {
      this.pendingAgencies = res.data.data
    }).catch(() => {}).finally(() => {
      this.loadingAgencies = false
    })
  }
}
</script>
