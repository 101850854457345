<template>
    <div>
        <v-card class="rounded-xl pa-3 text-center">
            <v-img height="200" width="200" class="mx-auto rounded-circle" v-if="agency.user.profile_photo" :src="agency.user.profile_photo"></v-img>
            <v-img height="200" width="200" class="mx-auto rounded-circle" v-else src="../../assets/images/agency.png"></v-img>
            <div class="mt-3 text-h5 text-capitalize text-center font-weight-medium primary--text"><span>{{agency.name}}</span></div>
            <v-card-text class="pb-0">
                <p class="text-truncate d-block paragraph--text caption">{{agency.user.about_me}}</p>
            </v-card-text>
            <v-card-actions>
                <div style="cursor: pointer;" @click="viewagencyDetails(agency)" class="text-center">
                    <v-icon large>mdi-eye</v-icon>
                    <div class="caption paragraph--text text-center">View Details</div>
                </div>
                <v-spacer></v-spacer>
                <div class="text-center">
                    <v-switch
                    class="mx-auto mt-0 mb-2"
                    @change="changeStatus"
                    inset
                    small
                    v-model="activeAgency"
                    :color="activeAgency ? 'success' : 'error'"
                    hide-details
                    ></v-switch>
                    <div class="caption paragraph--text text-capitalize text-center">{{agency.user.status}}</div>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import admin from '@/web_services/admin'

export default {
  props: ['agency'],
  data () {
    return {
      activeAgency: 1
    }
  },
  methods: {
    changeStatus () {
      if (this.activeAgency) this.agency.user.status = 'Active'
      else this.agency.user.status = 'Inactive'
      const payload = {
        status: this.agency.user.status.toLowerCase()
      }
      admin.updateAgencyStatus(this.agency.id, payload).then(res => {}).catch(() => {
        this.agency.user.status = this.agency.user.status === 'Active' ? 'Inactive' : 'Active'
        this.activeAgency = this.agency.user.status === 'Active' ? 1 : 0
      })
    },
    viewagencyDetails (agency) {
      this.$store.dispatch('setAgency', agency)
      this.$router.push({ name: 'agencyDetails', params: { id: agency.id } })
    }
  },
  created () {
    this.activeAgency = this.agency.user.status === 'Active' ? 1 : 0
  }
}
</script>
